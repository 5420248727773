export enum AreaType {
	Area = 1,
	Complex,
	SubArea,
	Fibrehood,
	Zone,
	Block,
	MDU,
}

export enum EvotelAreaType {
	Area = 1,
	Complex = 2,
	SubArea = 3,
	City = 4,
	Suburb = 5,
	MDU = 6,
	SUBMDU = 7,
}

export enum TicketType {
	Civil = 5,
	Fibre,
	Installation,
	LightLevels = 9,
	PowerDown,
	Maintenance,
	Other,
	FailedConfiguration,
	SlowSpeed,
}

export enum WorkOrderType {
	ALL = 0,
	INSTALL = 1,
	REPAIR = 1024,
}

export enum FormAction {
	CREATE = 0,
	EDIT,
	VIEW,
}

export enum PremiseAdminTab {
	AREA_TYPES = 0,
	AREA_GATES,
	AREA_TYPES_TASKS,
	AREA_STATUS,
	TRANSITIONS,
	BUILDING_PMO_CITIES,
}

export enum StateStatus {
	IN_PROGRESS = 'In Progress',
	NOT_STARTED = 'Not Started',
	SKIPPED = 'Skipped',
	COMPLETE = 'Complete',
	ON_HOLD = 'On Hold',
}

export enum DueDateStatues {
	UP_COMING_SOON = 'Up coming soon',
	DUE_SOON = 'Due very soon',
	OVER_DUE = 'Over due',
	UNSET = 'Unset',
}
export enum GateStateStatus {
	COMPLETE = 'Completed gate',
	SKIPPED = 'Gate has incomplete tasks',
	CURRENT = 'Current area gate',
	SELECTED = 'Selected area gate',
	FUTURE = 'Future gate without any started or completed tasks',
	FUTURE_INCOMPLETE = 'Future gate with some in progress or completed tasks',
}

export const INTERNAL_DOMAIN = '.aex.rocks';
export const EXTERNAL_DOMAIN = '.aex.systems';

export const KEY_AUTH_TOKEN = 'auth-token';
export const HEADER_AUTH = 'Authorization';
export const PROVIDER = 'provider_id';
export const ENTITY = 'entity';
export const IS_ISP_ADMIN = 'is_isp_admin';
export const COUNTRY_CODE = 'country_code';

export const NO_RESPONSE_STATUS = 0;
