export const Globals: any = {
	Status: {
		PENDING: '029715DE-80AE-469F-A344-E984588E76D1',
		SCHEDULED: '5EAD01C1-08AC-4512-83B8-950543625083',
		NEW_REPAIR: 'B5D3490B-19B1-4FCE-BB11-CEA8A645CBA8',
		REPAIR_INPROGRESS: '123A0F5C-6491-4819-A218-4548E8996B14',
		REPAIR_PENDING: '3209001A-B29B-41B3-9F45-0E3FA11DCB51',
		TESTING: 'B5927C9C-CA0C-41B6-817C-C9EFA024C8F3',
	},
	colors: {
		group0: {
			primary: '#7e57c2',
			secondary: '#7e57c280',
		},
		group1: {
			primary: '#9c64a6',
			secondary: '#ffc4ff',
		},
		group2: {
			primary: '#ff0000',
			secondary: '#ffc4c4',
		},
		group3: {
			primary: '#ff6600',
			secondary: '#FDDDC2',
		},
		group4: {
			primary: '#800080',
			secondary: '#D7AED7',
		},
		group5: {
			primary: '#F060D6',
			secondary: '#FED3DD',
		},
		group6: {
			primary: '#008000',
			secondary: '#14EE4A',
		},
		group7: {
			primary: '#CD5C5C',
			secondary: '#E6C4C0',
		},
		group8: {
			primary: '#b1bfca',
			secondary: '#e3f2fd',
		},
		group9: {
			primary: '#1ECBE1',
			secondary: '#dcf7fa',
		},
		group10: {
			primary: '#95bf40',
			secondary: '#eff5e2',
		},
		group11: {
			primary: '#0000b3',
			secondary: '#dadaff',
		},
		group12: {
			primary: '#c8a415',
			secondary: '#f6e7ab',
		},
		group13: {
			primary: '#c97b63',
			secondary: '#ffddc1',
		},
		group14: {
			primary: '#5870cb',
			secondary: '#c0cfff',
		},
		group15: {
			primary: '#006200',
			secondary: '#ebffeb',
		},
		group16: {
			primary: '#707070',
			secondary: '#cfcfcf',
		},
		group17: {
			primary: '#c8a415',
			secondary: '#ffff81',
		},
		group18: {
			primary: '#c97b63',
			secondary: '#ffddc1',
		},
		group19: {
			primary: '#bc0e0a',
			secondary: '#ffa2b1',
		},
		group20: {
			primary: '#FF0380',
			secondary: '#FFE8F3',
		},
		group21: {
			primary: '#FF6868',
			secondary: '#FFE8F3',
		},
		group22: {
			primary: '#ffaa1d',
			secondary: '#ffffc6',
		},
		undefined: {
			primary: '#6c757d',
			secondary: '#dcf7fa',
		},
		all: {
			primary: '#6c757d',
			secondary: '#dcf7fa',
		},
	},
};
