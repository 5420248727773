import { Component } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ConfigService } from './root-services/config.service';

@Component({
	selector: 'app-root',
	template: `
		<link rel="stylesheet" [href]='cssUrl'>
		<ngx-ui-loader></ngx-ui-loader>
		<div ngxUiLoaderBlurred fxLayout="column" fxLayoutGap=".1rem" class="vh-100 vw-100">
			<div class="content-container" toastContainer fxFlex>
				<router-outlet></router-outlet>
			</div>
		</div>
	`,
})
export class AppComponent {

	public cssUrl: SafeResourceUrl;

	constructor(
		private readonly configService: ConfigService,
		public sanitizer: DomSanitizer,
	) {
		this.cssUrl = sanitizer.bypassSecurityTrustResourceUrl(`${this.configService.operator}.css`);
	}

}
