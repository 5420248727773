import { getLocalStoreItem, IPagedResponse, mapToNull, setLocalStoreItem } from '@aex/ngx-toolbox';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ENTITY, PROVIDER } from '@app/tools/lib/consts';
import { SERVER_SETTINGS, Utils } from '@app/tools/lib/utils';
import { ConfigApi, OperatorApi, SettingsApi } from '@app/tools/root-services/api';
import { environment } from '@env/environment';
import { COUNTRY_CODE, IS_ISP_ADMIN } from '@tools/lib/consts';
import { ParamMetaData } from '@tools/lib/param-meta-data';
import { ITokenResult } from '@tools/root-services/types';
import { capitalize, merge } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { IConfig, IEnvFile, IGlobalConfig, IOperator } from './types';

export let STARTUP_DATA: IConfig;

@Injectable()
export class ConfigService {

	private _fnoName: string;

	constructor(
			private readonly http: HttpClient,
	) { }

	public readonly parentHostUrl: string = getParentHostUrl();
	private appConfig: any;

	private _operatorId: string;
	private _isfnoRole:boolean;
	private readonly _token = Utils.parameter('token') || Utils.parameter('aex_token') || environment.token;

	public get operatorId(): string { return this._operatorId; }
	public get operator(): string { return this._fnoName; }
	public get isfno():boolean { return this._isfnoRole !== null && this._isfnoRole !== undefined; }

	private _userId: string;
	public get userId(): string { return this._userId; }

	public get operatorServerAlias(): string {
		return STARTUP_DATA?.operatorServerAlias || this._fnoName;
	}

	public get operatorPrettyName(): string {
		return STARTUP_DATA?.prettyName || capitalize(this._fnoName);
	}

	public get operatorCanDeleteReprovision(): boolean {
		return STARTUP_DATA?.canDeleteReprovision || false;
	}

	public get operatorPppoeEnabled(): boolean {
		return STARTUP_DATA?.pppoeEnabled || true;
	}

	public get operatorKml(): string {
		return SERVER_SETTINGS?.kmlServerUrl;
	}

	public get appSettings(): any { return this.appConfig; }

	public get appData(): any { return this.appConfig.data; }

	public get getToken(): string { return this._token; }

	public get getProvider(): string { return getLocalStoreItem(`${ this._fnoName } - ${ PROVIDER }`); }

	public get getEntity(): string { return getLocalStoreItem(`${ this._fnoName } - ${ ENTITY }`); }

	public get getCountryCode(): string { return getLocalStoreItem(`${ this._fnoName } - ${ COUNTRY_CODE }`); }

	public get useAssetIntegrationService(): boolean {
		return this.appSettings.global.useAssetIntegrationService || false;
	}

	public get collectionDayChangeAllowed(): boolean {
		return this.appSettings.global.collectionDayChangeAllowed || false;
	}

	public get collectionDayChangePeriod(): number {
		return this.appSettings.global.collectionDayChangePeriod || 0;
	}

	public get configValues(): IGlobalConfig {
		return this.appSettings.global;
	}

	private static setApiUrls(): void {
		SERVER_SETTINGS.serverUrl = environment.serverUrl;
		SERVER_SETTINGS.ipServerUrl = environment.ipPoolServerUrl;
		SERVER_SETTINGS.paymentServerUrl = environment.paymentServiceUrl;
		SERVER_SETTINGS.purchaseOrderServerUrl = environment.purchaseOrderServiceUrl;
		SERVER_SETTINGS.searchServerUrl = environment.searchServiceUrl;
		SERVER_SETTINGS.solarServerUrl = environment.solarServiceUrl;
		SERVER_SETTINGS.radiusServerUrl = environment.radiusServiceUrl;
		SERVER_SETTINGS.billingServiceUrl = environment.billingServiceUrl;
		SERVER_SETTINGS.workOrderEventUrl = environment.workOrderEventUrl;
		SERVER_SETTINGS.eventsUrl = environment.eventsUrl;
		SERVER_SETTINGS.speedTestUrl = environment.speedTestUrl;
		SERVER_SETTINGS.assetsServiceUrl = environment.assetsServerUrl;
		SERVER_SETTINGS.kmlServerUrl = environment.kmlServerUrl;
	}

	public getOperatorSettings(params: any = {}): Observable<any> {
		return this.http.get(SettingsApi.settings(), {
			params: new ParamMetaData({handleError: 'settings'})
					.set('operator_guid', this.operatorId)
					.setQuery(params.query)
					.withAllCount(),
		});
	}

	public loadAppConfig(): Observable<void> {
		this._userId = this.getClaim('user_id');
		this._operatorId = this.getClaim('operator_id');
		this.getRole('fno');
		if (!this._operatorId)
			return of(null);
		this.loadAppInits();
		return this.http.get<IEnvFile>(ConfigApi.dynamicEnvironment)
			.pipe(
				switchMap((envConfig: IEnvFile) => {
					merge(
						environment,
						envConfig.environments[getConfigName()],
						environment.override,
					);
					ConfigService.setApiUrls();
					return this.getFno().pipe(
						switchMap((operator) => {
							this._fnoName = operator.name?.toLowerCase();
							this.loadAppInits();
							return this.http.get<IConfig>(ConfigApi.configSettings(this._fnoName));
						}),
					);
				}),
				catchError(() => this.http.get<IGlobalConfig>(ConfigApi.configSettings(DEFAULT_CONFIG))),
				tap(config => {

					this.appConfig = config;
					if (this.appConfig.global.isIsp)
						SERVER_SETTINGS.serverUrl = environment.ispServerUrl;
					this.setPortalUrl();
				}),
				mapToNull(),
			);
	}

	private getFno(): Observable<IOperator> {
		return this.http.get<IPagedResponse<IOperator>>(OperatorApi.operator(), {
			params: new ParamMetaData({ handleError: 'operator' })
				.set('guid', this._operatorId),
		}).pipe(
			map((operators: IPagedResponse<any>) => operators?.items[0]),
		);
	}
	private setPortalUrl(): void {
		switch (environment.name) {
			case 'stage':
				SERVER_SETTINGS.portalServerUrl = this.appConfig.stage.fnoPortalBaseUrl;
				break;
			case 'qa':
				SERVER_SETTINGS.portalServerUrl = this.appConfig.qa.fnoPortalBaseUrl;
				break;
			case 'preprod':
				SERVER_SETTINGS.portalServerUrl = this.appConfig.preprod.fnoPortalBaseUrl;
				break;
			case 'prod':
				SERVER_SETTINGS.portalServerUrl = this.appConfig.prod.fnoPortalBaseUrl;
				break;
			default:
					SERVER_SETTINGS.portalServerUrl = this.appConfig.stage.fnoPortalBaseUrl;
		}
	}
	private getClaim(name: string): string {
		return this.getLocalToken()?.claims.map(c => c.split(':')).find(c => c[0] === name)[1];
	}

	private getRole(name: string): void {
		const role = this.getLocalToken()?.claims.map(c => c.split(':')).find(c => c[1] === name);
		if (role)
			this._isfnoRole = !Utils.isEmpty(role[1]);
	}

	public getLocalToken(): ITokenResult {
		const token = this.getToken;
		return token ? JSON.parse(atob(token.split('.')[1])) : null;
	}

	private loadAppInits(): void {
		const provider = Utils.parameter('provider');
		if (provider)
			setLocalStoreItem(`${ this._fnoName } - ${ PROVIDER }`, provider, true);

		const entity = Utils.parameter('entity');
		if (entity)
			setLocalStoreItem(`${ this._fnoName } - ${ ENTITY }`, entity, true);

		const isIspAdmin = Utils.parameter('is_isp_admin');
		if (isIspAdmin && entity)
			setLocalStoreItem(`${ this._fnoName } - ${ IS_ISP_ADMIN }`, entity, true);

		const countryCode = Utils.parameter(COUNTRY_CODE);
		if (countryCode)
			setLocalStoreItem(`${ this._fnoName } - ${ COUNTRY_CODE }`, countryCode, true);
	}
}

function getParentHostUrl(): string {
	if (document.location.ancestorOrigins?.[0])
		return document.location.ancestorOrigins?.[0];
	else
		return document.referrer ? document.referrer : document.location.href;
}


function getConfigName(): string {
	return environment.override?.name || environment.name;
}

const DEFAULT_CONFIG = 'default';
