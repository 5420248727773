import { IProduct } from '@app/root-services/types';

export class PlatformError {
	constructor(public cause?: any) {}
}

export interface IAuthResponse {
	auth_token: string
}

export interface ICustomer {
	id: string
	peid:string
	premises: Partial<IPremises>[]
	first_name: string
	mobile_number: string
	email: string
	last_name: string
	billing_address: string
	billing_city: string
	billing_country: string
	billing_postal_code: string
	billing_province: string
	billing_suburb: string
	business_name: string
	home_number: string
	identity_number: string
	identification_type_id: number
	parent_id: string
	physical_address: string
	physical_city: string
	physical_country: string
	physical_postal_code: string
	physical_province: string
	physical_suburb: string
	portal_user_id: string
	registration_number: string
	username: string
	vat_number: string
	work_number: string
	password?: string
	full_name: string
	created_at: string
	updated_at: string
}

export interface IRole {
	id: string,
	name: string,
	description: string,
}

export interface IPremises {
	id?: string
	latitude: number
	longitude: number
	street_number: string
	street_name: string
	suburb: string
	city: string
	country_code: string
	postal_code: string
	province: string
	intersection: string
	type_id?: string
	customer_id: string
	name: string
	full_address: string
	address_string?: string
	mdu_number: string
	mdu_number_int: number
	mdu_name: string
	external_reference: string
	asset_reference: string
	created_at: string
	updated_at: string
}

export interface IPremisesUpdate {
	premise: Partial<IPremises>
	update_for_empty_customer?: boolean
}

export interface IInstaller {
	name: string
	id: string
	assigned_company: string
	parent_id: string
	registration_number: string
	support_number: string
	website: string
	email: string
	active: boolean
	enabled: boolean
}

export interface ITokenResult {
	iss?: string;
	iat?: number;
	uid?: string;
	claims?: string[];
	exp?: number;
	alg?: string;
}

export interface ITableSettings {
	sortBy?: string,
	searchBy?: string,
	desc?: boolean,
	start?: number,
	end?: number,
	searchString?: string,
}

export interface IAssets{
	enabled: boolean,
	attributes: IAsset[]
}

export interface IAsset{
	required: boolean,
	description: string,
	created_at: string,
	deleted_at: string,
	work_order_id?: string,
	service_id?: string,
	attributes: any[]
}

export interface IIspFnoWorkOrder {
	isp_work_order: IWorkOrder,
	fno_work_order: IWorkOrder,
	product: Partial<IProduct>
}

export interface IWorkOrder{
	id: string,
	type_id: number,
	account_id: string,
	reference: string,
	on_hold: boolean,
	completed_date: string,
	close_date: string,
	schedule_date: string,
	schedule_time: string,
	external_reference: string,
	category: string,
	updated_at: string,
	created_at: string,
	status_id: string,
	assigned: string,
	assigned_id: string,
	assigned_user_id: string,
	status: string,
	type: string,
	service_id: string,
	last_comment: string,
	created_by: string
	closed: boolean
	installer_id: string
	comment: string
	last_updated_days: number
	days_open: number,
	description: string,
	summary: string
}

export interface IMacAddress {
	mac_address: string
}

export interface IIpAddress {
	ip: string
}

export interface ICoverageRegions {
	id?: number,
	region_id?: number,
	region_name?: string,
	coverage_id?: number,
	created_at?: string,
	updated_at?: string,
}

export interface ICoverage {
	id?: number,
	name?: string,
	created_at?: string,
	updated_at?: string,
}

export interface IAexRegion {
	id: number,
	name: string,
	latitude: number,
	longitude: number,
	created_at: string,
	updated_at: string,
	zoom_level?: number,
}

export interface ICoverageInterconnectsList {
	id: number,
	name: string,
	created_at: string,
	updated_at: string,
	coverage_regions_count: number,
	interconnects_count: number,
	coverage_providers_count: number,
}

export interface ICoverageInterconnects {
	id: number,
	name: string,
	created_at: string,
	updated_at: string,
	coverage_regions: ICoverageRegions[],
}

export interface IProviderCoverage {
	id: number,
	provider_id: number,
	coverage_id: number,
	created_at: string,
	updated_at: string,
	provider_name?: string,
	coverage_name?: string,

	coverage: ICoverageInterconnectsList,
	provider: IProvider,
}

export interface IProvider {
	id: string,
	name: string,
	guid: string,
	registration_number: string,
	support_number: string,
	website: string,
	email: string,
	active: boolean,
	created_at: string,
	updated_at: string,
}

export interface ICoverageProvider {
	id?: number,
	provider_id?: number,
	provider?: IProvider,
	coverage_id?: number,
	coverage?: ICoverageInterconnectsList,
}

export interface IRegionOlt {
	id?: number,
	region_id?: number,
	region_name?: string,
	network_node_id?: number,
	network_node_name?: string,
	created_at?: string,
	updated_at?: string,

	region?: IAexRegion,
	network_node?: INetworkNode,

}

export interface INetworkNode {
	id: number,
	network_id: number,
	operator_id: number,
	type: string,
	node_type: string,
	parent_id?: number,
	children?: string,
	identifier: string,
	status: string,
	reference: string,
	description: string,
	updated_at: string,
	created_at: string,

	network_information: INetworkInformation,
}

export interface INetworkInformation {
	DT: string,
	DVER: string,
}

export interface IDevice {
	id: number,
	network_id: number,
	operator_id: number,
	type: string,
	node_type: string,
	parent_id: number,
	children?: string,
	identifier: string,
	status: string,
	reference: string,
	description: string,
	updated_at: string,
	created_at: string

	information: IDeviceInformation
}

export interface IDeviceInformation {
	Handle_Type: string,
	Handle_Id: string,
	Device_Id: string,
	Device_Type: string,
	name: string,
	deviceIP: string,
	state: string,
	active: string
}

export interface IGoogleCoOrdinates{
	googleMarkers: {
		lat: number,
		lng: number,
	}[],
	googlePolygons: {
		lat: number,
		lng: number,
	}[][]
}

export interface IStatusBlockInformation {
	label: string
	value: any
	disabled?: boolean
	titlecase?: boolean
	type?: string
	highlightLine?: string
	length?: number
}

export interface IStatusAlarmInformation {
	message: string
	status: string
}

export interface IOperationalStatus {
	service: string
	last_updated: string
	pop_id: string
	pop_description: string
	pop_status: string
	pop_name: string
	serial_number: string
	populated_ports: number[]
	alerts: string[]
	alarms: string[]
}

export interface IDeviceHistory {
	tx_status: string
	sfp_light_level: number
	added: string
	tx_light_level: number
	alarms: string[]
	rx_light_level: number
	oper_status: string
	rx_status: string
	reference: string
	alerts: string[]
	olt_name: string
	ont_distance: number
	vendor: string
	olt: string
	sfp_status: string
	name: string
	admin_status: string
	model: string,
	status: string
}

export interface IDeviceHistoryCSV {
	sfp_light_level: string
	added: string
	tx_light_level: string
	alarms: string
	rx_light_level: string
	alerts: string
	status: string
}

export interface IServiceStatus {
	gpon_status: string
	pppoe_status: string
	service_port: number | string
	network: string
	pop: string
	serial_number: string
	ip_address: string
	isp_modem_mac: string
	nni_mac: string
	cpe: string
	model: string
	configuration: string
	devices_connected: string
	lease_expiry: string
	modem_port_speed: number
	modem_connected: number
	object_status: string
	order_status: string
	status_last_updated: string
}

export interface INetworkPower {
	olt_port: string
	last_updated?: string
	distance:number
	rx_power: number
	rx_status: string
	sfp_power: number
	sfp_status: string
	sfp_rx_power: number
	sfp_rx_status: string
	tx_power: number
	tx_status: string
	switch: string
	sfp_sync_speed: string
	switch_port: number
	lease_expiry: string
}

export interface IConfiguration {
	vlans: string
	download_speed: number
	upload_speed: number
	ont_port: number
	service_provider: string
	speeds_success: boolean
	vlans_success: boolean
	last_updated?: string
}

export interface IWifiDevice {
	ssid: string
	mac_address: string
	current_down_speed: number
	current_up_speed: number
	signal: number
	noise: number
	signal_to_noise: number
	signal_quality: number
}

export interface IWifiInformation {
	pppoe_username: string;
	pppoe_password: string;
	device_id: string;
	configured_wifi: IConfiguredWifi;
	added: Date;
}

export interface Result<T>{
	result: T,
	statusCode: number
	status: 'success' | 'fail' | 'processing'
	message: string,
}

export interface IWifiInformationResult
		extends Result<IWifiInformation> {}

export interface IConnectedDevicesResult
		extends Result<IConnectedDevices> {}

export interface IConfiguredWifi {
	transport_power: string;
	auth_mode: string;
	encryption_mode: string;
	connection_status: string;
	frequency?: ISsid[];
}

export interface ISsid {
	ssid: string;
	band_width: string;
	wmm: string;
	channel: string;
	channel_width: string;
	information?: string;
}

export interface IConnectedDevices {
	added: Date;
	devices: IFieldValuePair[][];
}

export interface IFieldValuePair {
	field: string;
	value: string;
}

export interface IRadiusStatus {
	pppoe_username: string
	status: string
}

export interface IAssetRequirement {
	enabled: boolean;
	work_order_id?: string;
	service_id?: string
	attributes: IAssetAttribute[] | any;
}

export interface IAssetAttribute {
	required?: boolean;
	description: string;
	type?: string;
	value?: string | number;
}

export interface IServiceAssetsCollection {
	serviceType: IAssetAttribute[],
	darkType: IAssetAttribute[],
	waveType: IAssetAttribute[]
}

export interface IBoltOnService {
	id: string
	parent_id: string;
	bolt_on_id: string;
	parent_service_name: string;
	parent_service_provider_id: string;
	parent_service_provider: string;
	bolt_on_service_name: string;
	bolt_on_service_provider_id: string;
	bolt_on_service_provider: string;
}

export interface IBoltOnProduct {
	id: string;
	parent_id: string;
	bolt_on_id: string;
	default_work_order_type_id: number;
	parent_product_name: string;
	bolt_on_product_name: string;
}

// NOSONAR - Class implements the interfaces which is snake-case fields
export class ServiceStatusViewModel implements IOperationalStatus, IServiceStatus, INetworkPower, IConfiguration {

	private serviceConfig : IFullServicePortConfiguration = null;
	private serviceStatus: IFullServiceStatus = null;
	private radiusStatus: IRadiusStatus = null;
	private readonly Waiting = '';

	//#region --- IFullServicePortConfiguration ---

	public get service_port() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceConfig === null) ? this.Waiting : this.serviceConfig.service_port?.toString();
	}
	public get ip_address() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceConfig === null) ? this.Waiting : this.serviceConfig.ip_address;
	}
	public get isp_modem_mac() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceConfig === null) ? this.Waiting : this.serviceConfig.isp_modem_mac;
	}
	public get nni_mac() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceConfig === null) ? this.Waiting : this.serviceConfig.nni_mac;
	}
	public get configuration() : string {
		return (this.serviceConfig === null) ? this.Waiting : this.serviceConfig.configuration;
	}
	public get devices_connected() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceConfig === null) ? this.Waiting : this.getDevicesConnectedAsString();
	}
	public get pppoe_status() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceConfig === null) ? this.Waiting : this.serviceConfig.pppoe_status;
	}
	public get order_status() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceConfig === null) ? this.Waiting : this.serviceConfig.order_status;
	}
	public get last_updated() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceConfig === null) ? null : this.serviceConfig.last_updated;
	}
	public get pop_id() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceConfig === null) ? this.Waiting : this.serviceConfig.pop_id?.toString();
	}
	public get pop_description() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceConfig === null) ? this.Waiting : this.serviceConfig.pop_description;
	}
	public get pop_status() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceConfig === null) ? this.Waiting : this.serviceConfig.pop_status;
	}
	public get vlans() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceConfig === null) ? this.Waiting : this.serviceConfig.vlans;
	}
	public get download_speed() : number { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceConfig === null) ? 0 : this.serviceConfig.download_speed;
	}
	public get upload_speed() : number { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceConfig === null) ? 0 : this.serviceConfig.upload_speed;
	}
	public get ont_port() : number { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceConfig === null) ? 0 : this.serviceConfig.ont_port;
	}
	public get service_provider() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceConfig === null) ? this.Waiting : this.serviceConfig.service_provider;
	}
	public get speeds_success() : boolean { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceConfig === null) ? false : this.serviceConfig.speeds_success;
	}
	public get vlans_success() : boolean { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceConfig === null) ? false : this.serviceConfig.vlans_success;
	}
	//#endregion --- IFullServicePortConfiguration ---

	//#region --- IFullServiceStatus ---
	public get network() : string {
		return (this.serviceStatus === null) ? this.Waiting : this.serviceStatus.network;
	}
	public get pop() : string {
		return (this.serviceStatus === null) ? this.Waiting : this.serviceStatus.pop;
	}
	public get serial_number() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceStatus === null) ? this.Waiting : this.serviceStatus.serial_number;
	}
	public get gpon_status() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceStatus === null) ? this.Waiting : this.serviceStatus.gpon_status;
	}
	public get cpe() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceStatus === null) ? this.Waiting : this.serviceStatus.cpe;
	}
	public get model() : string {
		return (this.serviceStatus === null) ? this.Waiting : this.serviceStatus.model;
	}
	public get lease_expiry() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceStatus === null) ? this.Waiting : this.serviceStatus.lease_expiry;
	}
	public get modem_port_speed() : number { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceStatus === null) ? 0 : this.serviceStatus.modem_port_speed;
	}
	public get modem_connected() : number { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceStatus === null) ? 0 : this.serviceStatus.modem_connected;
	}
	public get object_status() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceStatus === null) ? this.Waiting : this.serviceStatus.object_status;
	}
	// Two last_updated dates: Presently this one is not used. Used is from FullServiceStatusConfiguration
	public get status_last_updated() : string	{ // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceStatus === null) ? this.Waiting : this.serviceStatus.last_updated;
	}
	public get service() : string {
		return (this.serviceStatus === null) ? this.Waiting : this.serviceStatus.service;
	}
	public get pop_name() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceStatus === null) ? this.Waiting : this.serviceStatus.pop_name;
	}
	public get alerts(): string[] {
		return (this.serviceStatus === null) ? null : this.serviceStatus.alerts;
	}
	public get alarms(): string[] {
		return (this.serviceStatus === null) ? null : this.serviceStatus.alarms;
	}
	public get populated_ports() : number[] { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceStatus === null) ? null : this.serviceStatus.populated_ports;
	}
	public get olt_port() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceStatus === null) ? this.Waiting : this.serviceStatus.olt_port;
	}
	public get distance() : number { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceStatus === null) ? 0 : this.serviceStatus.distance;
	}
	public get rx_status() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceStatus === null) ? this.Waiting : this.serviceStatus.rx_status;
	}
	public get rx_power() : number { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceStatus === null) ? 0 : this.serviceStatus.rx_power;
	}
	public get sfp_status() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceStatus === null) ? this.Waiting : this.serviceStatus.sfp_status;
	}
	public get sfp_power() : number { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceStatus === null) ? 0 : this.serviceStatus.sfp_power;
	}
	public get sfp_rx_status() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceStatus === null) ? this.Waiting : this.serviceStatus.sfp_rx_status;
	}
	public get sfp_rx_power() : number { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceStatus === null) ? 0 : this.serviceStatus.sfp_rx_power;
	}
	public get tx_power() : number { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceStatus === null) ? 0 : this.serviceStatus.tx_power;
	}
	public get tx_status() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceStatus === null) ? this.Waiting : this.serviceStatus.tx_status;
	}
	public get switch() : string {
		return (this.serviceStatus === null) ? this.Waiting : this.serviceStatus.switch;
	}
	public get sfp_sync_speed() : string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceStatus === null) ? this.Waiting : this.serviceStatus.sfp_sync_speed;
	}
	public get switch_port() : number { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.serviceStatus === null) ? 0 : this.serviceStatus.switch_port;
	}
	//#endregion --- IFullServiceStatus ---

	public get radius_status(): string { // NOSONAR - Class implements the interfaces which is snake-case fields
		return (this.radiusStatus === null) ? 'No Attempts' : this.radiusStatus.status;
	}

	private getDevicesConnectedAsString(): string {
		if ((this.serviceConfig.devices_connected === null) || (this.serviceConfig.devices_connected === 0))
			return 'None';
		else
			return this.serviceConfig.devices_connected.toString();
	}

	public setViewModel(
		aServiceConfig : IFullServicePortConfiguration,
		aServiceStatus: IFullServiceStatus,
		radiusStatus: IRadiusStatus,
	) : void {
		this.serviceConfig = aServiceConfig;
		this.serviceStatus = aServiceStatus;
		this.radiusStatus = radiusStatus;
	}
}

export interface IFullServicePortConfiguration {
	service_port : number;
	ip_address: string;
	isp_modem_mac: string;
	nni_mac: string;
	configuration: string;
	devices_connected: number;
	pppoe_status: string;
	order_status: string;
	last_updated: string;
	pop_id: number;
	pop_description: string;
	pop_status: string;
	vlans: string;
	download_speed: number;
	upload_speed: number;
	ont_port: number;
	service_provider: string;
	speeds_success: boolean;
	vlans_success: boolean;
	configuration_type: string;
}

export interface IFullServiceStatus {
	network: string;
	pop: string;
	serial_number: string;
	gpon_status: string;
	cpe: string;
	model: string;
	lease_expiry: string;
	modem_port_speed: number;
	modem_connected: number;
	object_status: string;
	last_updated: string;
	service: string;
	pop_name: string;
	alerts: string[];
	alarms: string[];
	populated_ports: number[],
	olt_port: string;
	distance: number;
	rx_status: string;
	rx_power: number;
	sfp_status: string;
	sfp_power: number;
	sfp_rx_status: string;
	sfp_rx_power: number;
	tx_power: number;
	tx_status: string;
	switch: string;
	sfp_sync_speed: string;
	switch_port: number;
}

export interface ISearchOptions{
	id:any;
	name: string;
}
