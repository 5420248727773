import { LOCALE_ID, Inject, Injectable } from '@angular/core';
import { CalendarEventTitleFormatter, CalendarEvent } from 'angular-calendar';
import { DatePipe } from '@angular/common';
import { IWorkOrderEvent } from '../models/event.model';

@Injectable()
export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {
	constructor(@Inject(LOCALE_ID) private readonly locale: string) {
		super();
	}

	// You can override any of the methods defined in the parent class

	public month(event: CalendarEvent): string {
		return `<b>${new DatePipe(this.locale).transform(
			event.start,
			'h:mm a',
			this.locale,
		)}</b> ${event.title}`;
	}

	public week(event: IWorkOrderEvent): string {
		return this.calEventFormat(event);
	}

	public day(event: IWorkOrderEvent): string {
		return this.calEventFormat(event);
	}

	public calEventFormat(event: IWorkOrderEvent): string {
		// eslint-disable-next-line
		return  `<div class="cal-text-title primary-${event.group}">
              ${new DatePipe(this.locale).transform(event.start, 'h:mm a', this.locale)}
            </div>
            <dl class="cal-text-sub-title">
              <dd>${ event.title }</dd>
              <dd>${ event.subTitle }</dd>
            </dl>` ;
	}

	public weekTooltip(event: IWorkOrderEvent, title: string): string {
		if (!event.meta.tmpEvent)
			return super.weekTooltip(event, title);
		return '';
	}

	public dayTooltip(event: CalendarEvent, title: string): string {
		if (!event.meta.tmpEvent)
			return super.dayTooltip(event, title);
		return '';
	}

}
