import { AlreadyReportedError, isHandledError, isPromiseRejection, NavigationService } from '@aex/ngx-toolbox';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({providedIn: 'root'})
export class GlobalErrorHandler implements ErrorHandler {

	constructor(
			private readonly injector: Injector,
	) { }

	private _toast: ToastrService;
	private get toast(): ToastrService {
		if (!this._toast)
			this._toast = this.injector.get(ToastrService);
		return this._toast;
	}

	private _nav: NavigationService;
	private get nav(): NavigationService {
		if (!this._nav)
			this._nav = this.injector.get(NavigationService);
		return this._nav;
	}

	public handleError(error: any): void {
		const lError = isPromiseRejection(error) ? error.rejection : error;
		if ((!isHandledError(lError) || !lError.handled) && !(lError instanceof AlreadyReportedError))
			if (lError.name === 'TimeoutError') {
				this.toast.warning('The server took too long to respond, you may be experiencing slow connectivity', 'Server Timeout');
				console.warn(lError);
			} else {
				this.toast.error(lError.message, 'Client Error');
				console.error(lError);
			}

		if (!lError.handled) {
			this.nav.cancelLoading();
			throw new AlreadyReportedError('This error has already been reported above');
		}
	}

}
