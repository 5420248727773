import { Routes } from '@angular/router';
import { BASE_ROUTES } from '@tools/lib/utils';

export const APP_ROUTES: Routes = [
	{
		path: BASE_ROUTES.assets.name,
		loadChildren: () => import('./modules/assets/assets.module').then(m => m.AssetsModule),
	},
	{
		path: BASE_ROUTES.billing.name,
		loadChildren: () => import('./modules/billing/billing.module').then(m => m.BillingModule),
	},
	{
		path: BASE_ROUTES.boltOnServices.name,
		loadChildren: () => import('./modules/bolt-on-services/bolt-on-services.module').then(m => m.BoltOnServicesModule),
	},
	{
		path: BASE_ROUTES.civils.name,
		loadChildren: () => import('./modules/civils-pmo/civils-pmo.module').then(m => m.CivilsPmoModule),
	},
	{
		path: BASE_ROUTES.deviceManagement.name,
		loadChildren: () => import('./modules/device-management/device-management.module').then(m => m.DeviceManagementModule),
	},
	{
		path: BASE_ROUTES.interconnectsManagement.name,
		loadChildren: () => import('./modules/interconnects-management/interconnects-management.module')
			.then(m => m.InterconnectsManagementModule),
	},
	{
		path: BASE_ROUTES.ipPools.name,
		loadChildren: () => import('./modules/ip-pools/ip-pools.module').then(m => m.IpPoolsModule),
	},
	{
		path: BASE_ROUTES.ispArea.name,
		loadChildren: () => import('./modules/isp-area-admin/isp-area-management.module').then(m => m.IspAreaManagementModule),
	},
	{
		path: BASE_ROUTES.payments.name,
		loadChildren: () => import('./modules/payments-managent/payment-management.module').then(m => m.PaymentManagementModule),
	},
	{
		path: BASE_ROUTES.reach.name,
		loadChildren: () => import('./modules/reach/reach.module').then(m => m.ReachModule),
	},
	{
		path: BASE_ROUTES.reportingGrids.name,
		loadChildren: () => import('./modules/reporting-grids/reporting-grids.module').then(m => m.ReportingGridsModule),
	},
	{
		path: BASE_ROUTES.rica.name,
		loadChildren: () => import('./modules/rica/rica.module').then(m => m.RicaModule),
	},
	{
		path: BASE_ROUTES.scheduler.name,
		loadChildren: () => import('./modules/scheduler/scheduler.module').then(m => m.SchedulerModule),
	},
	{
		path: BASE_ROUTES.serviceStatus.name,
		loadChildren: () => import('./modules/service-status/service-status.module').then(m => m.ServiceStatusModule),
	},
	{
		path: BASE_ROUTES.speedTest.name,
		loadChildren: () => import('./modules/speed-test/speed-test.module').then(m => m.SpeedTestModule),
	},
	{
		path: BASE_ROUTES.support.name,
		loadChildren: () => import('./modules/support/support.module').then(m => m.SupportModule),
	},
	{
		path: BASE_ROUTES.vlans.name,
		loadChildren: () => import('./modules/vlans/vlans.module').then(m => m.VlansModule),
	},
	{
		path: BASE_ROUTES.workOrder.name,
		loadChildren: () => import('./modules/work-order/work-order.module').then(m => m.WorkOrderModule),
	},
	{
		path: BASE_ROUTES.workOrderEvent.name,
		loadChildren: () => import('./modules/work-order-event/work-order-event.module').then((m) => m.WorkOrderEventModule),
	},
	{
		path: BASE_ROUTES.billingReport.name,
		loadChildren: () => import('./modules/billing-report/billing-report.module').then(m => m.BillingReportModule),
	},
	{
		path: BASE_ROUTES.serviceConfiguration.name,
		loadChildren: () => import('./modules/service-configuration/service-configuration.module').then(m => m.ServiceConfigurationModule),
	},
	{
		path: BASE_ROUTES.voip.name,
		loadChildren: () => import('./modules/voip/voips.module').then(m => m.VoipsModule),
	},
	{
		path: '',
		redirectTo: '',
		pathMatch: 'full',
	},
];

