import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EXTERNAL_DOMAIN, INTERNAL_DOMAIN } from './consts';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

		const hostUrl = window.location.href;
		if (hostUrl.includes(INTERNAL_DOMAIN)) {
			const url = req.url
				.replace(EXTERNAL_DOMAIN, INTERNAL_DOMAIN);
			return next.handle(req.clone({ url }));
		}
		return next.handle(req);
	}
}
