import { LiveMockService } from '@aex/ngx-toolbox';
import { Injectable } from '@angular/core';
import { AreaApi } from '@app/tools/public-api';
import { CIVIL_STATUS, CIVIL_STATUS_SUMMARY_TYPES } from './mock-responses';

@Injectable({providedIn: 'root'})
export class MockRegistrations {

	constructor(
			mockService: LiveMockService,
	) {
		mockService.merge([
			{uri: AreaApi.areaCivilStatus(':id'), data: CIVIL_STATUS, enabled: false},
			{uri: AreaApi.areaCivilStatusSummaryTypes, data: CIVIL_STATUS_SUMMARY_TYPES, enabled: false},
		]);

		try {
			const local = require('./my-mock/mock');
			if (local?.provision)
				local.provision(mockService);
		} catch {}
	}
}

